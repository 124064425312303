<template>
	<div class="el-content">
		<div class="auth-release">
			<div class="auth-release-left">
				<h3>授权及发布微信小程序</h3>
				<a-steps direction="vertical"  :curren="active">
				   <a-step>
					   <template #description>
						   <div class="arl-cont">
							   <div class="arl-cont-left">
								   <div class="arl-title">已注册微信小程序，立即授权发布。</div>
								   <div class="arl-desc">点击立即授权后，请使用微信小程序管理员账号进行扫码授权，授权过程中请勾选所有权限以确保小程序功能完整性。
								   如未注册小程序，可以从公众号后台免微信认证创建小程序或直接前往“微信公众平台”注册企业主体的小程序帐号。</div>
									<a target="_blank" href="https://mp.weixin.qq.com/">官方注册小程序</a>
							   </div>
							   <div class="arl-cont-right">
								   <a-button 
									   type="primary" 
									   v-if="auth && auth.is_auth == 0"
									   @click="getAuthUrl">立即授权
									</a-button>
								   <span v-if="auth && auth.is_auth == 1"><i class="el-icon-success"></i>已授权</span>
							   </div>
						   </div>
					   </template>
				   </a-step>
				   <a-step>
					   <template #description>
							<div class="arl-cont">
								<div class="arl-cont-left">
								   <div class="arl-title">已注册微信小程序，立即授权发布。</div>
								   
								   <div class="arl-info">
								   	<div>上传状态：
								   		<span v-if="auth.is_upload == 1 || auth.is_upload == 2"><i class="el-icon-success" ></i>已上传</span>
										<span v-else>未上传</span>
								   	</div>
									<div class="arl-info-qrcpde" v-if="preview_qrcode">
										<div>预览二维码</div>
										<img class="preview-qrcode" :src="preview_qrcode" alt="">
									</div>
								   </div>
								   
								</div>
								<div class="arl-cont-right">
									<a-tooltip title="获取预览二维码">
										<i 
										v-if="auth.is_upload == 1 || auth.is_upload == 2" 
										class="iconfont icon-qrcode" 
										style="cursor: pointer;font-size: 22px;position: relative;top: 3px;"
										@click="getPreviewQrcode"></i> 	
									</a-tooltip>
									<a-button :disabled="auth.is_auth == 0" type="primary" @click="setAuthWxappUpload" v-if="auth.is_upload == 0">立即上传</a-button>
									<a-button :disabled="auth.is_auth == 0" v-if="auth.is_upload == 1 || auth.is_upload == 2" type="primary" @click="setAuthWxappUpload">重新上传</a-button>
								</div>
						  </div>
					   </template>
				   </a-step>
				   <a-step>
						<template #description>
							<div class="arl-cont">
								<div class="arl-cont-left">
									<div class="arl-title">已上传小程序，立即提交审核</div>
									<div class="arl-info">
										<div style="margin-left: 8px;">审核状态：
											<span class="arli-status0" v-if="audit.status == 0 ">未审核</span>
											<span class="arli-status1" v-if="audit.status == 1 ">审核中</span>
											<span class="arli-status2" v-if="audit.status == 2 ">审核通过</span>
											<span class="arli-status3" v-if="audit.status == 3 ">审核拒绝</span>
											<span class="arli-status4" v-if="audit.status == 4 ">审核撤回</span>
										</div>
									   
										<div v-if="audit.status == 3" class="arl-info-reason">
											<div style="width:80px;">拒绝原因：</div>
											<div v-html="audit.reason"></div>
										</div>
									</div>
								</div>
								<div class="arl-cont-right">
									<a-button type="primary" :disabled="auth.is_auth == 0" @click="setAuthWxappSubmit" v-if="audit.status == 0">立即审核</a-button>
									<a-button type="primary" :disabled="auth.is_auth == 0" @click="setAuthWxappSubmit" v-if="audit.status == 3 || audit.status == 4">重新审核</a-button>
									<a-button type="danger" :disabled="auth.is_auth == 0" @click="setAuthWxappCancel" v-if="audit.status == 1">撤销审核</a-button>
								</div>
							</div>
						</template>
				   </a-step>
				   <a-step>
					   <template #description>
						   <div class="arl-cont">
								<div class="arl-cont-left">
									<div class="arl-title">立即发布小程序</div>
									<div>发布状态：
										<span class="arli-status2" v-if="auth.is_push == 1">已发布</span>
										<span class="arli-status0" v-if="auth.is_push == 0">未发布</span>
									</div>
								
								</div>
								<div class="arl-cont-right">
									<a-button :disabled="auth.is_auth == 0 && audit.status != 2" v-if="auth.is_push == 0" type="primary" @click="setAuthWxappPush">立即发布</a-button>
									<!-- <el-button v-if="auth.is_push == 1" type="success" size="mini" @click="getPreviewQrcode">获取预览二维码</el-button> -->
								</div>
							</div>
					   </template>
				   </a-step>
				</a-steps>
			</div>
		</div>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import releaseModel from '@/api/wxmini-release.js'
import setModel from '@/api/set.js'
import { useRoute } from 'vue-router'
export default{
    name:"page-auth-release",
    setup(){
        const _d = reactive({
            active:1,
            auth:{},  //授权信息
            audit:{}, //审核状态
            preview_qrcode:"",  //预览小程序码
        })

        checkIsAuth()
        getAuthWxappAuditStatus()
        const options = useRoute().query
        if( options.auth_code ){
            setOpenAuth(options.auth_code)
        }

        //检测当前小程序是否授权
        function checkIsAuth(){
            releaseModel.checkCurrentProgramIsAuth(res=>{
                _d.auth = res
                if( res.is_upload == 1 || res.is_upload == 2){
                    _d.active =2
                }
            })
    	}

        //获取小程序的审核状态
    	function getAuthWxappAuditStatus(){
            releaseModel.getProgramCheckStatus(res=>{
                _d.audit = res
                if(res.status !=0 ){
                    _d.active = 3
                }
            })
    	}

        //立即授权
    	function getAuthUrl(){
            setModel.getWxOpenAuthUrl().then(res=>{
                location.href=res
            })
    	}

        //设置授权
    	function setOpenAuth(auth_code){
            setModel.setWxOpenAuth("min_program",auth_code).then(res=>{
                checkIsAuth()
            })
    	}

        //上传小程序
    	function setAuthWxappUpload(){
            releaseModel.uploadAuthProgram()
    	}

        //提交审核
    	function setAuthWxappSubmit(){
            releaseModel.submitAudit(res=>{
                getAuthWxappAuditStatus();
            })
    	}

        //撤销审核
    	function setAuthWxappCancel(){
            releaseModel.submitAuditCancel()
    	}
    	
        //发布小程序
    	function setAuthWxappPush(){
            releaseModel.publishProgram(()=>{
                _d.active = 4
                getPreviewQrcode()
            })
    	}

        //获取预览二维码
    	function getPreviewQrcode(){
            releaseModel.getPreviewQrcode(res=>{
                _d.preview_qrcode = res
            })
    	}

        return {
            ...toRefs(_d),
            checkIsAuth,
            getAuthWxappAuditStatus,
            getAuthUrl,
            setOpenAuth,
            setAuthWxappUpload,
            setAuthWxappSubmit,
            setAuthWxappCancel,
            setAuthWxappPush
        }
    }
}
</script>
<style lang="scss">
	.auth-release{
		height: 600px;
		display: flex;
		.el-step__description{
			padding-right: 24px !important;
		}
		&-left{
			width: 60%;
			height: 500px;
			.arl-cont{
				display: flex;
				justify-content: space-between;
				// align-items: center;
                min-height: 150px;
				
				&-left{
					width: 80%;
				}
				&-right{
					width: 20%;
					text-align: right;
					color: #333;
					font-size: 15px;
					
				}
				i{
					color: #39cd39;
					margin-right: 8px;
				}
				
				.arl-title{
					color: #666;
					font-size: 15px;
					font-weight: bold;
				}
				.arl-desc{
					margin-top: 8px;
					color: #999;
				}
			}
			
			a{
				text-decoration: none;
				color: #177CE3;
			}
		}
		
		.arl-info{
			color: #666;
			font-size: 14px;
			margin-top: 8px;
			
			
		}
		.arl-info-reason{
			display: flex;
			padding: 8px;
			background: rgba(255, 178, 178, 0.2);
			border-radius: 5px;
		}
		.arli-status0,.arli-status1,.arli-status3{
			color: #CC0000;
		}
		.arli-status2{
			color: #67c23a;
		}
		.arli-status4{
			color: orange;
		}
		
		.preview-qrcode{
			width: 100px;
			height: 100px;
			margin-top: 8px;
		}
	}
</style>
