import {reactive} from "vue";
import releaseModel from "@/api/wxmini-release";

//获取当前微信小程序版本预览二维码
export function useWxAppVersion(){
    let wvState = reactive({
        previewQrcode:"",   //当前版本预览二维码
    })
    releaseModel.getMiniappPreviewQrcode(res=>{
        wvState.previewQrcode = res
    })

    //检测是否有新版本
    const checkHaveNewVersion =()=>releaseModel.checkMiniappVersion()

    return { wvState ,checkHaveNewVersion }
}


//微信小程序推送版本信息
export function useWxAppPushVersion(){
    let wpvState = reactive({
        version:"",
        version_desc:"",
        preview_qrcode:"",
    })

    releaseModel.getPushVersion(res=>{
        wpvState.version = res.version
        wpvState.version_desc = res.desc
        wpvState.preview_qrcode = res.qr_code
    })

    return { wpvState }
}

//快速发布版本
export function usePublishVersion(){
    let pvState = reactive({
        is_plugins:false,
        uuid:"",
    })

    //创建推送版本
    function pushVersionNow(version){
        releaseModel.createPushVersion(version,{is_live:pvState.is_plugins,ci:true},res=>{
            pvState.uuid = res.uuid
            releaseModel.checkNodeEv(result=>{
                if( result.status ){
                    releaseModel.pushVersionByCi(res.uuid,version,pvState.is_plugins)
                }
            })
        })
    }

    return{ pvState,pushVersionNow }
}

//普通发布
export function useNormalPublishVersion(){
    let npvState = reactive({
        step:1,
        uuid:"",
        login_qrcode:'',    //上传工具登陆二维码
        preview_code:"",    //小程序预览码
        logining:false,
        active:1
    })

    function createPublishVersion(version,is_live){
        releaseModel.createPushVersion(version,{is_live},res=>{
            npvState.step = 2
            npvState.uuid = res.uuid
            npvState.login_qrcode = ''
            npvState.preview_qrcode = ''
        })
    }

    function getAuthQrcode(){
        releaseModel.getAuthLoginQrcode(npvState.uuid,res=>{
            npvState.login_qrcode = res.qrcode
            checkAuthLogin()
        })
    }

    function checkAuthLogin(){
        let timer = setInterval(function(){
            releaseModel.checkLogin(npvState.uuid,res=>{
                if( res.status === 0 ){
                    npvState.logining = false
                    npvState.active = 2
                    npvState.step = 3
                    clearInterval(timer)
                }
                if(res.status === 2){
                    npvState.step = 2
                }
            })
        },2000)
    }

    function uploadCode(push,is_plugins){
        npvState.step = 4
        releaseModel.uploadCode(npvState.uuid,push.version,push.version_desc,is_plugins ? 1:0,res=>{
            if(res.code == 0 ){
                npvState.step = 5
                npvState.active = 2
            }else{
                npvState.step = 3
            }
        })
    }

    function getPreview() {
        releaseModel.getPreview(npvState.uuid,res=>{
            npvState.step = 6
            npvState.preview_qrcode = res.qrcode
        })
    }

    return { npvState,createPublishVersion,getAuthQrcode,uploadCode,getPreview}
}


















