import { $post,$cloudPost } from '@/api/http.js'
import tool from '@/util/tool.js'

class WxminiRelease{
    /**
     * 检测小程序版本
     */
    checkMiniappVersion() {
        $post("checkNeedToUpdate").then(res=>{
            tool.message(res.message)
        })
    }
    /**
     * 获取当前小程序版本
     * @param {function} fn
     */
    getMiniappPreviewQrcode(fn) {
        $post("getProgramQrcode").then(res=>{
            fn(res.data)
        })
    }

    /**
     * 获取当前推送版本信息
     * @param {function} fn
     */
    getPushVersion (fn){
        $post("getMinProgramNewVersion").then(res=>{
            fn(res.data)
        })
    }

    /**
     * 创建推送版本
     * @param {string} version  版本号
     * @param {object} plugins  插件
     * @param {function} fn
     */
    createPushVersion(version,plugins,fn) {
        let param = { version,...plugins}
        $post("getMinProgramApply",param).then(res=>{
            fn(res.data)
        })
    }
    /**
     * 获取授权登录二维码
     * @param {string} uuid
     * @param {function} fn
     */
    getAuthLoginQrcode(uuid,fn) {
        $cloudPost('getLogin',{uuid}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 上传代码
     * @param {string} uuid
     * @param {string} version
     * @param {string} version_desc
     * @param {number} is_plugins
     */
    uploadCode(uuid,version,version_desc,is_plugins,fn){
        let param = { version,version_desc,uuid,is_plugins}
        $cloudPost('setUpload',param).then(res=>{
            tool.message("上传成功")
            fn(res)
        })
    }

    /**
     * 获取预览二维码
     * @param {string} uuid
     * @param {function} fn
     */
    getPreview(uuid,fn){
        $cloudPost('getPreview',{uuid}).then(res=>{
            $cloudPost('release',{uuid}).then(()=>{ })  //释放端口
            fn(res.data)
        })
    }
    /**
     * 检测是否登录
     * @param {string} uuid
     * @param {function} fn
     */
    checkLogin(uuid,fn){
        $cloudPost('getLoginCheck',{uuid},false).then(res=>{
            if( res.data.code == 0){
                if( res.data.data.status == 2 ){
                    tool.message('登录二维码已过期，请重新创建推送版本',"warning")
                }
            }
            fn(res.data)
        })
    }
    /**
     * 检测当前小程序是否授权
     * @param {function} fn
     */
    checkCurrentProgramIsAuth(fn) {
        $post("code_getWxMinProgramDetail").then(res=>{
            fn(res.data)
        })
    }
    /**
     * 获取小程序的审核状态
     * @param {function} fn
     */
    getProgramCheckStatus(fn) {
        $post("code_getWxMinProgramAuditStatus").then(res=>{
            fn(res.data)
        })
    }
    /**
     * 上传小程序
     */
    uploadAuthProgram = function() {
        $post("code_setWxMinProgramUpload").then(()=>{
            tool.message("上传成功")
        })
    }
    /**
     * 小程序提交审核
     * @param {function} fn
     */
    submitAudit(fn) {
        $post("code_setWxMinProgramAuditSubmit").then(res=>{
            tool.message("提交成功")
            fn(res.data)
        })
    }

    /**
     * 撤销小程序审核
     */
    submitAuditCancel() {
        $post("code_setWxMinProgramAuditCancel").then(()=>{
            tool.message("审核已撤销")
        })
    }

    /**
     * 发布小程序
     * @param {function} fn
     */
    publishProgram (fn) {
        $post("code_setWxMinProgramPush").then(res=>{
            tool.message("发布成功")
            fn(res.data)
        })
    }
    /**
     * 获取小程序预览二维码
     * @param {function} fn
     */
    getPreviewQrcode(fn) {
        $post("code_getWxMinProgramTestQrCode").then(res=>{
            tool.message("发布成功")
            fn(res.data.url)
        })
    }

    /**
     * 检测 并安装CI上传环境
     * @param cb
     */
    checkNodeEv(cb){
        $post("checkNodeExport").then(res=>{
            if( res.data.status ){
                cb(res.data)
            }else{
                $post("installNodeExport").then(result=>{
                    if(result.data.status ){
                        cb(result.data)
                    }
                })
            }
        })
    }

    /**
     * 快速上传小程序
     * @param uuid
     * @param version   版本好
     * @param is_live   直播插件
     */
    pushVersionByCi(uuid,version,is_live){
        $post("ciMiniPush",{uuid,version,is_live}).then(res=>{
            if( res.data.status  ){
                tool.message("小程序上传成功")
            }
        })
    }
}


const wxminiReleaseModel = new WxminiRelease()
export default wxminiReleaseModel
